import React from 'react';
import { Link } from 'react-router-dom';
import './CategoriesList.scss';
import readConfig from '../../../utils/readClientConfiguration';
import productionConfiguration from '../../../../config/default.json';
import emmezetaPlaceholder from '../../../assets/img/emmezeta-placeholder.png';

const cdnBaseUrl = readConfig(productionConfiguration, 'cdn');

function getImageUrl(rawSource) {
  if (rawSource === 'false' || rawSource === false) {
    return emmezetaPlaceholder;
  }

  if (rawSource.includes(cdnBaseUrl)) {
    return `${rawSource}.webp`;
  }

  if (rawSource.includes('https://')) {
    return rawSource;
  }

  return `${cdnBaseUrl}/${rawSource}`;
}

const CategoriesList = ({ currentCategory }) => {
  // Check if there are any children to display
  if (!currentCategory.children) {
    return null;
  }

  return (
    <div className="categories-list">
      {currentCategory.children.map(({ name, canonical_url, current_store_paths }) => {
        const baseUrl = getImageUrl(current_store_paths[0].base_url_image);

        return (
          <Link to={`/${canonical_url}`} key={name}>
            <div className="categories-list__item" style={{
              backgroundImage: `url("${baseUrl}")`
            }}/>

              {/*<div className="categories-list__name">{name}</div>*/}
              {/*{!!current_store_paths.length && (*/}
              {/*  <img loading="lazy" className="categories-list__image" src={baseUrl} alt="category" />*/}
              {/*)}*/}
            <div className="categories-list__name">{name}</div>
          </Link>
        );
      })}
    </div>
  );
};

export default CategoriesList;
